/**
 * angular imports
 */
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
/**
 * my imports
 */
import { MainService } from './main/main.service';
import { SystemService } from './system/system.service';
import { UserService } from './user/user.service';
import { NodeService } from './node/node.service';
import { FileService } from './file/file.service';
import { CommentService } from './comment/comment.service';
import { TaxonomyTermService } from './taxonomy/taxonomy-term.service';
import { TaxonomyVocabularyService } from './taxonomy/taxonomy-vocabulary.service';
import { ViewService } from './view/view.service';
import { EntityService } from './entity/entity.service';
import { CommerceOrderService } from './commerce/commerce-order.service';
import { MenuService } from './menu/menu.service';
import { SystemConnection } from './models/system';
import { FacebookOAuthService } from './fboauth/fboauth.service';
import { PushNotificationsService } from './push_notifications/push-notifications.service';
import { FlagService } from './flag/flag.service';
import { VboService } from './vbo/vbo.service';
import { CommerceCartService } from './commerce/commerce-cart.service';
import { CommerceLineItemService } from './commerce/commerce-line-item.service';
import { CommerceCustomerProfileService } from './commerce/commerce-customer-profile.service';
import { CommercePaymentTransactionService } from './commerce/commerce-payment-transaction.service';
import { CommerceProductService } from './commerce/commerce-product.service';
import { CommerceProductReferenceService } from './commerce/commerce-product-reference.service';
import { WebformService } from './webform/webform.service';
import { WebformSubmissionService } from './webform/submission.service';
import { SchemaService } from './schema/schema.service';
import { FieldCollectionItemService } from './field-collection-item/field-collection-item.service';
import { CommerceCheckoutService } from './commerce/commerce-checkout.service';

/**
 * implement APP_INITIALIZER
 *
 * @param systemService system service to connect
 * @see https://gillespie59.github.io/2016/12/04/angular2-code-before-rendering.html
 */
export function init(systemService: SystemService): () => Promise<SystemConnection> {
  return () => {
    return
    const connectionObservable = systemService.connect(true);
    return connectionObservable;
  };
}

/**
 * main services module with providers
 * if you do not need to import all the services you need to make your own module and import the required providers only
 * ngx-cookie package is required
 *
 * @see https://www.npmjs.com/package/ngx-cookie
 */
// @dynamic
@NgModule({
  imports: [
    HttpClientModule,
  ],
  providers: [
    MainService,
    SystemService,
    UserService,
    NodeService,
    FileService,
    CommentService,
    TaxonomyTermService,
    TaxonomyVocabularyService,
    ViewService,
    EntityService,
    CommerceOrderService,
    CommerceCartService,
    CommerceLineItemService,
    CommerceCustomerProfileService,
    CommercePaymentTransactionService,
    CommerceProductService,
    CommerceProductReferenceService,
    CommerceCheckoutService,
    MenuService,
    FacebookOAuthService,
    PushNotificationsService,
    FlagService,
    VboService,
    WebformService,
    WebformSubmissionService,
    SchemaService,
    FieldCollectionItemService,
    {
      provide: APP_INITIALIZER,
      useFactory: init,
      deps: [SystemService],
      multi: true
    }
  ],
})
export class Drupal7ServicesModule { }

export {
  MainService,
  SystemService,
  UserService,
  NodeService,
  FileService,
  CommentService,
  TaxonomyTermService,
  TaxonomyVocabularyService,
  ViewService,
  EntityService,
  CommerceOrderService,
  CommerceCartService,
  CommerceLineItemService,
  CommerceCustomerProfileService,
  CommercePaymentTransactionService,
  CommerceProductService,
  CommerceProductReferenceService,
  CommerceCheckoutService,
  MenuService,
  FacebookOAuthService,
  PushNotificationsService,
  FlagService,
  VboService,
  WebformService,
  WebformSubmissionService,
  SchemaService,
  FieldCollectionItemService
};
