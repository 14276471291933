import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { NavController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { Observable, from, map, of } from 'rxjs';

export interface FavoriteEvent {
  id: number;
}

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private _storage: Storage | null = null;

  myID = false;
  private data = {};
  delegate = [];

  constructor(
    private storage: Storage,
    private nav: NavController,
    public alertController: AlertController
    ) { 
      this.init();
    }

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    const storage = await this.storage.create();
    this._storage = storage;
  }

  // Create and expose methods that users of this service can
  // call, for example:
  set(key: string, value: any) {
    this._storage?.set(key, value);
  }

  async storeEntity(storageKey: string, entity: any, entityKey: 'nid' | 'id' | 'item_id') {
    let newEntity = [];
    await this._storage.get(storageKey).then(storedObj => {
      if (storedObj) {
        const index = storedObj.map((o) => o[entityKey]).indexOf(entity[entityKey]);
        if (index !== -1) {
          storedObj[index] = entity;
        } else {
          storedObj.push(entity);
        }
      } else {
          storedObj = [entity];
        }
      this._storage.set(storageKey, storedObj);
      return newEntity = storedObj;
    });
    return newEntity;
  }

  getEach() {
    this._storage?.forEach((value, key, index) => {
      this.get(key).then(val => {
        console.log(val);
      });
    });
  }

  async get(key: string) {
    return await this.storage?.get(key);
  }

  getAll(key: string): Observable<any> {
    return from(this._storage.get(key)).pipe(
      map((storedOperations: any) => {
        const storedObj = JSON.parse(storedOperations);
        if (storedObj && storedObj.length > 0) {
          return storedObj;
        } else {
          return of(false).toPromise();
        }
      })
    );
  }

  async clear() {
    this._storage.forEach((v, k, i) => {
        this._storage.remove(k);
    });
  }

  async remove(storageKey: string) {
    return this._storage.remove(storageKey).then(res => res).catch(() => false);
  }

  async checkMyID() {
      await this.storage.get('delegates').then(async (val) => {
        if (await val !== null && await val !== undefined) {
          this.myID = true;
          this.delegate = val;
        } else {
          this.myID = false;
        }
      });
    return this.myID;
  }

  async pushYourIDPage(feature) {
    const alert = await this.alertController.create({
      header: 'Add your Delegate ID',
      message: feature + ', you must first add your delegate ID.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
        }, {
          text: 'Add ID',
          handler: () => {
            this.nav.navigateForward('/my-id');
          }
        }
      ]
    });
    await alert.present();
  }

  setData(id, data) {
    this.data[id] = data;
  }

  getData(id) {
    return this.data[id];
  }

}
