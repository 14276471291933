// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.track-icon {
  margin: 0 auto;
  width: 4rem;
  height: 4rem;
}

.room-map {
  --background: none;
}

@media (prefers-color-scheme: dark) {
  .room-map {
    filter: invert(1);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/sessions/session-details/session-details.page.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,WAAA;EACA,YAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AACA;EACI;IACI,iBAAA;EAEN;AACF","sourcesContent":[".track-icon {\n    margin: 0 auto;\n    width: 4rem;\n    height: 4rem;\n}\n\n.room-map {\n    --background: none;\n}\n@media (prefers-color-scheme: dark) {\n    .room-map {\n        filter: invert(1);\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
