import { Component } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { StorageService } from './services/storage/storage.service';
import { Capacitor } from '@capacitor/core';
import { FirebaseCloudMessagesService } from './services/firebase-cloud-messages.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  standalone: true,
  imports: [IonicModule],
})

export class AppComponent {
  constructor(
    private storageService: StorageService,
    private fcmService: FirebaseCloudMessagesService,
  ) {
    this.init();
  }

  async init() {
    // Trigger the push setup
    if (Capacitor.getPlatform() !== 'web') {
      this.fcmService.initPush();
    }
  }

}
