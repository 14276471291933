import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { StorageService } from 'src/app/services/storage/storage.service';
import { AuthService } from 'src/app/services/auth.service';
import { FeedbackComponent } from 'src/app/modals/feedback/feedback.page';
@Component({
  selector: 'app-delegate',
  templateUrl: './delegate.component.html',
  styleUrls: ['./delegate.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, NgxQRCodeModule]
})

export class DelegateComponent {

  @Input() delegate: any;
  @Input() session: any;
  @Input() appUser: any;
  @Input() allowToggleFlags: boolean = false;
  @Input() showFlags: boolean = false;
  @Input() viewAsTable: boolean = false;
  @Output() flagDelegate: EventEmitter<any> = new EventEmitter();

  constructor(
    private router: Router,
    private storageService: StorageService,
    private auth: AuthService,
    private modalCtrl: ModalController
    ) { }


  navigate(url: string) {
    this.router.navigate([url]);
  }

  async submitFeedback(id: string) {
    const delegates = await this.auth.checkSavedDelegate();
    await this.storageService.checkMyID();

    if (this.storageService.myID) {

      const modal = await this.modalCtrl.create({
        component: FeedbackComponent,
        cssClass: 'feedback',
        componentProps: {
          delegates,
          selectedDelegate: id.toString()
        }
      });
      await modal.present();
      const result = await modal.onDidDismiss();
      if (result.role === 'submit') {
        if (result.data.feedback && result.data.delegate) {
          this.auth.createFeedbackWebformSubmission(result.data.delegate, result.data.feedback);
        }
      }
     } else { this.storageService.pushYourIDPage('To submit feedback'); }
  }

}
