import { Component, OnInit, ViewChild } from '@angular/core';
import { SlcapiService } from '../services/slcapi.service';
import { SessionGroup } from '../models/app.models';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonModal, IonicModule } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core/components';
import { EventComponent } from '../components/event/event.component';
@Component({
  selector: 'app-conference-schedule',
  templateUrl: './conference-schedule.page.html',
  styleUrls: ['./conference-schedule.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, EventComponent]

})
export class ConferenceScheduleComponent implements OnInit {
  @ViewChild(IonModal) modal: IonModal;

  days: SessionGroup[] = [];
  filteredDays: SessionGroup[];
  eventTypes = [];
  filterManualOptions = {
    type: '',
    day: '',
    title: '',
    building_name: '',
    track: '',
    favorited: null
  };

  years = this.slcapi.getYears().reverse();

  showYearFilter = false;

  constructor(
    private slcapi: SlcapiService,
    ) {}

    ngOnInit(): void {
      this.slcapi.currentEvents.subscribe(events => {
        if (events) {
          const filterTypes = this.slcapi.getEventPropsValuesWithCount(events, ['type']);
          if (filterTypes) {
            this.eventTypes = filterTypes.type.filter(t => t.name !== 'Session');
          }
          const days = this.slcapi.groupSession(events);
          const daysCopy: SessionGroup[] = this.slcapi.deepCopy(days);
  
          if (daysCopy) {
            daysCopy.map(d => {
              d.events = d.events.filter(e => e.type !== 'Session');
            });
            this.filteredDays = this.slcapi.deepCopy(daysCopy);
            this.days = daysCopy;

            this.slcapi.currentFilter.subscribe(filter => {
              if (filter) {
                Object.keys(filter).map(k => {
                  const v = filter[k];
                  this.filterManualOptions[k] = v;
                });
                this.filterManualDays();
              }
            });

            this.filterManualDays();
          }
        }
      });
    }

    toggleYear() {
      this.showYearFilter = !this.showYearFilter;
    }

    getEvents(showLoading: boolean = true) {
      this.slcapi.getEvents(showLoading);
    }
  
    cancel() {
      this.modal.dismiss(null, 'cancel');
    }
  
    confirm() {
      this.modal.dismiss(true, 'confirm');
    }
  
    onWillDismiss(event: Event) {
      const ev = event as CustomEvent<OverlayEventDetail<string>>;
      if (ev.detail.role === 'confirm') {
        this.filterManualDays();
      }
    }

    hasEvents() {
      if (this.filterManualOptions.favorited) {
        return this.slcapi.hasEvents(this.days);
      } else {
        return true;
      }
    }

    toggleFavorited() {
      this.filterManualOptions.favorited = !this.filterManualOptions.favorited;
      this.filterManualDays();
    }
  
    filterManualDays() {
      if (this.modal) {
        this.modal.dismiss();
      }
      for (let i = 0; i < this.days.length; i++) {
        this.days[i].events = this.filteredDays[i].events.filter(x => {
          for (const key of Object.keys(this.filterManualOptions)) {
            const val = this.filterManualOptions[key];
            
            if (key === 'title') {
              if (x.room.toLowerCase().indexOf(val.toLowerCase()) !== -1 || 
                  x.title.toLowerCase().indexOf(val.toLowerCase()) !== -1) {
                continue;
              } else {
                return false; // Exclude session
              }
            } else if (val && x[key] !== val) {
              return false; // Exclude session
            }
          }
          
          return true; // Include session
        });  
      }
    }

}
