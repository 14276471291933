import { PostalAddress } from './address';
import { ElavonResponse } from './elavon';

export const CommerceOrderStatus = {
    canceled: 'Canceled',
    cart: 'Cart',
    checkout_checkout: 'Checkout: Checkout',
    checkout_review: 'Checkout: Review',
    checkout_payment: 'Checkout: Payment',
    checkout_complete: 'Checkout: Complete',
    pending: 'Pending',
    processing: 'Processing',
    invoiced: 'Invoiced'
  } as const;

  export const CommercePaymentTransactionStatus = {
    canceled: 'canceled',
    pending: 'pending',
    success: 'success',
    failure: 'failure'
  } as const;

  export const CommerceComponentsState = {
    base_price: 'base_price',
    discount: 'discount',
    fee: 'fee'
  } as const;

  export interface CommerceProduct {
    sku: string;
    id: number;
    title: string;
    commerce_price: number;
    commerce_total_formatted: string;
    status: boolean;
  }

  export interface PaymentMethod {
    type: string;
    value: string;
    label: string;
    icon: string;
    checked: boolean;
    fill: string;
    access: boolean;
    available_offline: boolean;
    available_online: boolean;
    requires_billing: boolean;
    requires_card: boolean;
  }

  export interface AltPayment {
    payload: Array<string>;
    check_number: string;
    receipt_email: string;
  }
  export interface CreditCard {
    card_number: string;
    exp_month: string;
    exp_year: string;
    exp_date: string;
    code: string;
  }
  export interface CommerceComponents {
    name: typeof CommerceComponentsState[keyof typeof CommerceComponentsState];
    price: {
      amount: string | number;
      currency_code: string;
      data: {
        components: Array<object>;
      };
    };
    included: boolean;
  }

  export interface CommercePrice {
    amount: number;
    currency_code: string;
    data: {
      components: Array<CommerceComponents>;
    };
  }

  export interface CommerceLineItemDataContext {
    context: {
      button_text: string;
      product_ids: Array<number>;
      add_to_cart_combine: number;
      view: {
        view_name: string;
        display_name: string;
        human_name: string;
        page: string;
      };
    };
  }

  export interface CommerceLineItemEntity {
    order_id: string;
    type: string;
    commerce_product: string;
    quantity: string;
    line_item_label?: string;
    created?: string;
    changed?: string;
    data?: CommerceLineItemDataContext;
    commerce_unit_price?: CommercePrice;
    commerce_total?: CommercePrice;
    commerce_display_path?: string;
    rdf_mapping?: Array<string>;
    commerce_pricing_attributes?: string;
    line_item_title?: string;
    commerce_unit_price_formatted?: string;
    commerce_total_formatted?: string;
  }

  export interface CreatedCommerceLineItem extends CommerceLineItemEntity {
    line_item_id: string;
  }

  export interface CommerceCustomerProfile {
    status: string;
    type: string;
    uid: string;
    commerce_customer_address: PostalAddress;
    created?: string;
    changed?: string;
    profile_id?: string;
    revision_id?: string;
    revision_uid?: string;
    log?: string;
    revision_timestamp?: string;
    data?: boolean;
    rdf_mapping?: Array<string>;
  }

  export interface Payment {
    payment_method: string;
    status: typeof CommercePaymentTransactionStatus[keyof typeof CommercePaymentTransactionStatus];
    uid: number | string;
    order_id: number | string;
    amount: number;
    formattedAmount?: string;
    currency_code: string;
    message: string;
    remote_id: string;
    remote_status: string;
    created?: string;
    changed?: string;
  }

  export interface CommercePaymentTransaction extends Payment {
    transaction_id: string;
    revision_id: string;
    instance_id: string;
    message_variables: string;
    payload?: string;
    created: string;
    changed: string;
    data?: string;
  }

  export interface CommerceOrderData {
    last_cart_refresh: Date;
    payment_method: string; // Instance ID: ex. 'method|commerce_payment_method'
    commerce_payment_order_paid_in_full_invoked: string;
  }

  export interface CommerceOrder {
    order_number: string;
    revision_id: string;
    revision_uid: string;
    mail: string;
    status: typeof CommerceOrderStatus[keyof typeof CommerceOrderStatus];
    placed: string;
    log: string;
    revision_timestamp: number;
    revision_hostname: string;
    data: CommerceOrderData;
    order_id: string;
    type: string;
    uid: number;
    created: number;
    changed: number;
    hostname: string;
    commerce_line_items: Array<number>;
    commerce_order_total: CommercePrice;
    commerce_customer_billing: string;
    field_commerce_billy_cancel_date: string;
    field_commerce_billy_i_date: string;
    commerce_discounts: Array<object>;
    commerce_coupons: Array<object>;
    rdf_mapping: Array<string>;
    commerce_order_total_formatted: string;
    commerce_line_items_entities: Array<CommerceLineItemEntity>;
    commerce_customer_billing_entities: Array<CommerceCustomerProfile>;
    commerce_payment_transactions: Array<CommercePaymentTransaction>;
    commerce_order_balance: number;
    commerce_order_payments_amount: number;
    commerce_order_payments_amount_formatted: string;
    commerce_order_balance_formatted: string;
  }

  export interface APICommerceOrder {
    order_id: number;
    order_number: number;
    uid: number;
    mail: string;
    commerce_order_total: number;
    commerce_order_total_formatted: string;
    balance: number;
    balance_formatted: string;
    commerce_order_better_balance: number;
    commerce_order_better_balance_formatted: string;
    status: typeof CommerceOrderStatus[keyof typeof CommerceOrderStatus];
    state: typeof CommerceOrderStatus[keyof typeof CommerceOrderStatus];
    created: string;
    changed: string;
    placed: string;
    field_commerce_billy_i_date: string;
    field_commerce_billy_cancel_date: string;
    commerce_coupons: string;
    commerce_discounts: string;
    type: string;
    flag_early_order_registration: boolean;
    flag_check_in_by_order: boolean;
    flag_scanned_order_id: boolean;
    commerce_customer_billing?: string;
    commerce_customer_profiles?: Array<CommerceCustomerProfile>;
    commerce_payment_transactions?: Array<CommercePaymentTransaction>;
    commerce_order_payments_amount?: number;
    commerce_order_payments_amount_formatted?: string;
    commerce_line_items_amount?: number;
    commerce_line_items_amount_formatted?: string;
    order_owner: Array<any>;
    commerce_line_items?: any;
    commerce_line_items_grouped?: any;
    delegate_registrations?: object;
    custom_order_message?: string;
    custom_early_order_message?: string;
  }

  export interface CustomerProfileAttachToEntity {
    order_id: number;
    field_name: string;
    attach: CommerceCustomerProfile;
  }

  export interface CommerceCheckoutResponse {
    commerce_customer_billing: CommerceCustomerProfile;
    commerce_payment_transaction: CommercePaymentTransaction;
    commerce_order: CommerceOrder;
    elavon_response: ElavonResponse;
    view: APICommerceOrder;
  }
