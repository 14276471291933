import { Component, OnInit } from '@angular/core';
import { LoadingController, AlertController, Platform, ModalController, IonicModule } from '@ionic/angular';
import { Router } from '@angular/router';
import { StorageService } from '../services/storage/storage.service';
import { Pro } from '@ionic/pro';
import { SlcapiService } from '../services/slcapi.service';
import { Storage } from '@ionic/storage';
import { DiscussionQuestionComponent } from '../modals/discussion-question/discussion-question.page';
import { FeedbackComponent } from '../modals/feedback/feedback.page';
import { SessionGroup } from '../models/app.models';
import { format } from 'date-fns';
import { MessagesService } from '../services/messages.service';
import { AuthService } from '../services/auth.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.page.html',
  styleUrls: ['./schedule.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule]

})
export class ScheduleComponent implements OnInit {

  days: SessionGroup[] = [];
  version = environment.version;

  constructor(
    private platform: Platform,
    private router: Router,
    private slcapi: SlcapiService,
    public storage: Storage,
    private message: MessagesService,
    public loadingCtrl: LoadingController,
    public alertCtrl: AlertController,
    private storageService: StorageService,
    private auth: AuthService,
    private modalCtrl: ModalController,
    ) {

      this.automaticUpdate();
     }

     ngOnInit(): void {
      this.slcapi.currentEvents.subscribe(events => {
        if (events) {
          const days = this.slcapi.groupSession(events);
          const daysCopy: SessionGroup[] = this.slcapi.deepCopy(days);
          if (daysCopy) {
            daysCopy.map(d => {
              d.events = d.events.filter(e => e.type !== 'Session');
            });
            this.getCurrentSchedule(daysCopy);
          }
        }
      });
    }

    getEvents(showLoading: boolean = true) {
      this.slcapi.getEvents(showLoading);
    }

    async getCurrentSchedule(data: SessionGroup[]) {
      this.days = [];
      const currentDate = format(new Date().valueOf(), 'EEEE, MMMM dd');
      const todayEvents = data.filter(group => group.day === currentDate);
      if (todayEvents.length) {
        this.days.push(todayEvents[0]); // Current day
      } else {
        this.days.push(data[1]); // Sunday
      }
      return this.days;
    } 

  async submitDiscussionQuestion(discussionTitle, eventData) {
    const delegate = await this.auth.checkSavedDelegate();
    await this.storageService.checkMyID();

    if (this.storageService.myID) {

      const modal = await this.modalCtrl.create({
        component: DiscussionQuestionComponent,
        cssClass: 'discussion-question',
        componentProps: {
          event: eventData,
          user: delegate
        }
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        if (data.question) {
          this.auth.createDiscussionWebformSubmission(delegate, discussionTitle, data.question);
        }
      }

     } else { this.storageService.pushYourIDPage('To submit a panel question'); }

  }

  async submitFeedback() {
    const delegates = await this.auth.checkSavedDelegate();
    await this.storageService.checkMyID();

    if (this.storageService.myID) {

      const modal = await this.modalCtrl.create({
        component: FeedbackComponent,
        cssClass: 'feedback',
        componentProps: {
          delegates
        }
      });
      await modal.present();
      const result = await modal.onDidDismiss();
      if (result.role === 'submit') {
        if (result.data.feedback && result.data.delegate) {
          this.auth.createFeedbackWebformSubmission(result.data.delegate, result.data.feedback);
        }
      }
     } else { this.storageService.pushYourIDPage('To submit feedback'); }
  }

  navigate(url: string) {
    this.router.navigate([url]);
  }

  openSessionScheduleService(eventType) {
    this.storageService.setData(eventType, eventType);
    this.router.navigateByUrl('/sessions');
  }

  async automaticUpdate() {
    this.platform.ready().then(async () => {
      if (this.platform.is('capacitor')) {
        try {
          const currentVersion = await Pro.deploy.getCurrentVersion();
          const resp = await Pro.deploy.sync({updateMethod: 'auto'});
          if (currentVersion.versionId !== resp.versionId) {
            // We found an update, and are in process of redirecting you since you put auto!
          } else {
            // No update available
          }
        } catch (err) {
          // We encountered an error.
          // Here's how we would log it to Ionic Pro Monitoring while also catching:
          // Pro.monitoring.exception(err);
        }
      } else {}
    });
  }

  async updateApp() {
    this.platform.ready().then(async () => {
      if (this.platform.is('capacitor')) {
        try {
            const update = await Pro.deploy.checkForUpdate();
            if (update.available) {
              this.message.showLoading('Downloading Update...', true, 8000);
              await Pro.deploy.downloadUpdate((progress) => {
                console.log(progress);
              });
              await Pro.deploy.extractUpdate((progress) => {
                console.log(progress);
              });
              await this.confirmReload('Update Now', 'Apply the new update now.');
            } else {
              this.message.presentAlert('Up to Date', 'Your app is already on the latest version.');
            }
          } catch (err: any) {
            // We encountered an error.
            // Here's how we would log it to Ionic Pro Monitoring while also catching:
            Pro.monitoring.exception(err);
          }
      } else {}
    });
  }

  async confirmReload(title, text) {
    this.loadingCtrl.dismiss();
    const alert = await this.alertCtrl.create({
      header: title,
      message: text,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
        }, {
          text: 'Confirm',
          handler: () => {
            Pro.deploy.reloadApp();
          }
        }
      ]    });
    await alert.present();
  }
}
