// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ngx-qrcode {
  max-width: 240px;
  margin: 0 auto;
}

ion-card-title {
  font-size: 16px;
}

ion-thumbnail {
  --border-radius: 50%;
}

h1 {
  color: var(--ion-color-primary);
  font-weight: 600 !important;
}

ion-item {
  --inner-padding-start: 10px;
  --padding-start: 20px;
}

ion-img.id-badge {
  max-width: 160px;
  margin: 10px auto 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/delegate/delegate.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,cAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,oBAAA;AACJ;;AAEA;EACI,+BAAA;EACA,2BAAA;AACJ;;AAEA;EACI,2BAAA;EACA,qBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,sBAAA;AACJ","sourcesContent":["ngx-qrcode {\n    max-width: 240px;\n    margin: 0 auto;\n}\n\nion-card-title {\n    font-size: 16px;\n}\n\nion-thumbnail {\n    --border-radius: 50%;\n}\n\nh1 {\n    color: var(--ion-color-primary);\n    font-weight: 600 !important;\n}\n\nion-item {\n    --inner-padding-start: 10px;\n    --padding-start: 20px;\n}\n\nion-img.id-badge {\n    max-width: 160px;\n    margin: 10px auto 10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
