import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';
import { LoadingController, AlertController, NavController } from '@ionic/angular';
import { MessagesService } from './messages.service';
import { AuthService } from './auth.service';
import { StorageService } from './storage/storage.service';

export class LoginCredentials {
  username: string;
  password: string;
  constructor(username, password) {
    this.username = username;
    this.password = password;
  }
}

export class RegisterCredentials {
  user_name: {und: [{value: string}]};
  mail: string;
  pass: string;
  constructor(mail, pass, userName) {
    this.user_name = {und: [{value: userName}]};
    this.mail = mail;
    this.pass = pass;
  }
}

export interface UserRegister {
  uid: string;
  uri: string;
}

export class Session {
  sessid: string;
  session_name: string;
  token: string;
  user: any;
  constructor() {}
}

export interface FakeSession {
  user: {
    mail: string;
    uid: string;
    roles: object;
    user_roles: Array<string>;
  };
}

export class UserAccount {
  [key: string]: any;

  created: string;
  changed: string;
  name: string;
  mail: string;
  pass: string;
  legal_accept: boolean;
  notify: boolean;

  constructor(username: string, mail: string, pass: string) {
    this.created = new Date().valueOf().toString().substring(0, 10);
    
    this['user_name'] = '',
    this['secondary_email'] = '',
    this['contact_cell_number'] = '',
    this['linked_church'] = '',
    this['roles'] = [],

    this.name = username;
    this.mail = mail;
    this.pass = pass;
  }
  set(key, value) {
    UserAccount[key] = value;
  }
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  requestOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    public message: MessagesService,
    public storageService: StorageService,
    public alertCtrl: AlertController,
    public nav: NavController,
    public loadingCtrl: LoadingController) { }

    async getAppUser() { 
      let appUser = await this.storageService.get('appUser');
      if (appUser) {
        const options = this.auth.setupRequestOptions('csrf', appUser);
        this.http.get(environment.user.baseURL + appUser.user.uid, options).subscribe(res => {
          return appUser;
        }, async err => { 
          if (err) {
            appUser = await this.login('appUser', environment.appSettings.AppUser, false);
            console.log(appUser);
          }
        });
      } else {
        appUser = await this.login('appUser', environment.appSettings.AppUser, false);
      }
      return appUser;
    }
   
    async login(storageKey: string, body: LoginCredentials, showLoading: boolean): Promise<Session> {
      if (showLoading) {
        this.message.showLoading('Logging in...', false, 300);
      }
      return await this.http.post<any>(environment.user.login, JSON.stringify(body), this.requestOptions).toPromise().then(async (loggedInUser: Session) => {
        await this.setRoles(loggedInUser);
        await this.setFlagAccess(loggedInUser, null, null, storageKey);
        this.storageService.set(storageKey, loggedInUser);
        return loggedInUser;
      });
    }

    setRoles(session) {
      Object.keys(environment.user.roleTypes).map(key => {
        Object.values(session.user.roles).map(role => session.user[key] = environment.user.roleTypes[key].indexOf(role) !== -1 ? true : false);
        return session;
      });
      return session;
    }

    async setFlagAccess(session: any, entityType: string = 'entityform', bundle: string, storageKey?: string) {
      const options = this.auth.setupRequestOptions('csrf', session);
      const flagAccess = await this.auth.get(environment.flag.permissions, options).toPromise();
      session.user.flag_access = {flag: {}, unflag: {}};
      const flagsArray = [];
      Object.keys(flagAccess).map((key: any) => {
        if (flagAccess[key].types.indexOf(bundle) !== -1) {
          flagsArray.push(flagAccess[key]);
        }
        Object.keys(session.user.roles).map(rid => {
          session.user.flag_access.flag[key] = flagAccess[key].flag[rid] ? true : false;
          session.user.flag_access.unflag[key] = flagAccess[key].unflag[rid] ? true : false;
          return session;
        });
      });
      await this.storageService.set('flags_'+bundle, flagsArray);
      if (storageKey) {
        this.storageService.set(storageKey, session);
      }
      return {flags: flagsArray, session};
    }
}
