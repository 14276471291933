import { Component, OnInit } from '@angular/core';
import { IonicModule, LoadingController, Platform } from '@ionic/angular';
import { Share } from '@capacitor/share';
import { SlcapiService } from '../services/slcapi.service';
import { Router } from '@angular/router';
import { StorageService } from '../services/storage/storage.service';
import { MessagesService } from '../services/messages.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Browser } from '@capacitor/browser';

@Component({
  selector: 'app-campus-map',
  templateUrl: './campus-map.page.html',
  styleUrls: ['./campus-map.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule]
})
export class CampusMapComponent implements OnInit {

  buildings: [];

  constructor(
    public loadingCtrl: LoadingController,
    public platform: Platform,
    private messsage: MessagesService,
    private slcapi: SlcapiService,
    private router: Router,
    private storageService: StorageService,    
  ) { }

  ngOnInit() {
    this.getCampus();
  }

  async getCampus() {
    const buildings = await this.storageService.get('campus');
    if (buildings) {
      this.buildings = buildings;
    } else {
      this.slcapi.getBuildings().subscribe(async data => {
        this.buildings = data;
      });
    }
  }

  getBuildingSessions(taxonomyTerm) {
    this.slcapi.filter.next({building_name: taxonomyTerm});
    this.router.navigate(['/sessions']);
  }

  getEvents(showLoading: boolean = true) {
    if (showLoading) {
      this.messsage.showLoading('Loading buildings...', true, 2000);
    }
    this.slcapi.getBuildings().subscribe(async data => {
      this.buildings = data;
    });
  }

  async shareImage(fileName) {
    const url = `https://lbc-downloads.com/downloads/imgs/${fileName}`;
    if (this.platform.is('capacitor')) {
      this.messsage.showLoading('Prepping your map...', true, 3000);
      const options = { files: [url] };
      await Share.share(options)
      .then(res => { console.log('Shared: ', res); })
      .catch(error => { console.log('Error: ', error); });
    } else {
      Browser.open({url});
    }
  }

}
