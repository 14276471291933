import { Injectable } from '@angular/core';

import { MainService } from '../main/main.service';
import { FormFromJSON } from '../models/forms';

/**
 * webform service for drupal
 *
 * @see BACK_END/admin/structure/services/list/END_POINT/resources for more details
 */
@Injectable()
export class SchemaService extends MainService {
  override entityType = 'ionic';

  /**
   * implement retrieve resource
   *
   * @param entityType the entity type
   * @param entityBundle the bundle of the entity type
   * @return object of the form schema
   */
  getSchema(entityType: string, entityBundle: string, viewMode: string): Promise<FormFromJSON> {
    return this.get(`${entityType}/${entityBundle}/${viewMode}`);
  }



}
