const baseURL = 'https://slconference.com/';

export const environment = {
  production: true,
  version: "5.7.0",
  firebaseConfig: {
    apiKey: "AIzaSyATIVZ-AgvK9FU9jUtOCd1tFmrwpGj9wRI",
    authDomain: "spiritual-leadership-conf-app.firebaseapp.com",
    databaseURL: "https://spiritual-leadership-conf-app.firebaseio.com",
    projectId: "spiritual-leadership-conf-app",
    storageBucket: "spiritual-leadership-conf-app.appspot.com",
    messagingSenderId: "79270241890",
    appId: "1:79270241890:web:92083e9d8590ab7e42933b",
    measurementId: "G-WJ21DG8QEN"
  },
  flag: {
    flag: baseURL + 'api/flag/flag.json',
    unflag: baseURL + 'api/unflag/',
    permissions: baseURL + 'api/ionic/flags/null.json'
  },
  webform: {
    baseURL: baseURL + 'webform/',
    create: baseURL + 'webform/submission.json',
    get: baseURL + 'webform/webform/',
  },
  user: {
    baseURL: baseURL + 'api/user/',
    login: baseURL + 'api/user/login.json',
    roleTypes: {
      UnverifiedRoles: ['unverified user'],
      ChurchRoles: ['church member'],
      RegularRoles: ['regular user'],
      AuthorizedRoles: ['app', 'administrator', 'staff', 'conference admin', 'volunteer'],
      AdminRoles: ['app', 'administrator', 'staff', 'conference admin'],
    },
  },
  node: {  
    baseURL: baseURL + 'node/',
  },
  views: {
    events: baseURL + 'api/events',
    campus: baseURL + 'api/campus',
    childcare: baseURL + 'api/node/20627.json',
    analytics: baseURL + 'api/analytics/',
    delegate: baseURL + 'api/delegate/',
  },
  appSettings: {
    AppUser: {username: 'restws_slcapp', password: 'slc!7media' },
    FavUser: {username: 'restws_slcfav', password: 'slc!7media' },
    AppUserUID: '4656',
    linkDevicePass: 'slc2022',
    resetDevicePass: 'slcReset'
  }
};
