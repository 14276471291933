import { Injectable } from '@angular/core';

import { MainService } from '../main/main.service';
import { ViewOptions } from '../models/view';

/**
 * view service for drupal using services_views module
 *
 * @see https://www.drupal.org/project/services_views
 */
@Injectable()
export class ViewService extends MainService {
  override entityType = '';

  /**
   * impelemnt retrieve resource
   *
   * @param viewMachineName view machine name
   * @param options parameters of filteration for this view
   * @return array of view rows OR view html code if the options format_output is 1
   */
  async getView(viewMachineName: string, options?: ViewOptions): Promise<any[]> {
    if (!options) {
      return this.get(viewMachineName);
    }
    const args = this.getContextualFilters(options?.args);
    const filters = this.getArgs(options?.filters);
    const results = await this.get(viewMachineName + args + filters);
    if (options?.remove_props) {
      this.removeProps(results, options.remove_props);
    }
    if (options.convert_options) {
      this.convertSelectOptions(results);
    }
    return results;
  }

}
