import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Storage } from '@ionic/storage';
import { StorageService } from '../../services/storage/storage.service';
import { IonicModule, LoadingController, ModalController } from '@ionic/angular';
import { environment } from '../../../environments/environment.prod';
import { DiscussionQuestionComponent } from '../../modals/discussion-question/discussion-question.page';
import { Platform } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SlcapiService } from 'src/app/services/slcapi.service';
import { SLCEvent } from 'src/app/models/app.models';
import { Browser } from '@capacitor/browser';
import { Share } from '@capacitor/share';

@Component({
  selector: 'app-session-details',
  templateUrl: './session-details.page.html',
  styleUrls: ['./session-details.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule]
})
export class SessionDetailsComponent {

  @Input() event: SLCEvent;

  constructor(
    public activeRoute: ActivatedRoute,
    public storage: Storage,
    public loadingCtrl: LoadingController,
    private modalCtrl: ModalController,
    public platform: Platform,
    private slcapi: SlcapiService,
    private auth: AuthService,
    public storageService: StorageService,
    ) { }

  checkRoomImage() {
    return this.event.floor_map?.src.includes('.svg') ? 'room-map' : '';
  }

  cancel() {
    this.modalCtrl.dismiss(null, 'cancel');
  }

  async toggle(event: SLCEvent) {    
    this.slcapi.toggleEvent(event);
  }

  async openExternalLink(url: string) {
    await Browser.open({ url });
  }

  async submitDiscussionQuestion(discussionTitle) {
    const delegate = await this.auth.checkSavedDelegate();
    await this.storageService.checkMyID();

    if (this.storageService.myID) {

      const modal = await this.modalCtrl.create({
        component: DiscussionQuestionComponent,
        cssClass: 'discussion-question',
        componentProps: {
          event: this.event,
          user: delegate
        }
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        if (data.question) {
          this.auth.createDiscussionWebformSubmission(delegate, discussionTitle, data.question);
        }
      }

     } else { this.storageService.pushYourIDPage('To submit a panel question'); }

  }

  async showLoading(title) {
    const loading = await this.loadingCtrl.create({
      message: title,
      backdropDismiss: true,
      duration: 3000
    });
    await loading.present();
    const { role, data } = await loading.onDidDismiss();
}


  async shareSession(session: SLCEvent) {
    this.showLoading('Formatting the session data for you...');
    const options = {
      message: 'Here is session by ' + session.speaker + ' about ' + session.title + ' on '
      + session.day + ' at ' + session.from + ' in ' + session.room + '.',
      subject: 'SLC ' + session.year + ' Session - ' + session.title,
      url: environment.node.baseURL + session.nid,
    };
    await Share.share(options)
      .then(() => {
        this.loadingCtrl.dismiss();
      })
      .catch(() => {
        this.loadingCtrl.dismiss();
      });
  }

}
