import { Component, OnInit } from '@angular/core';
import { SlcapiService } from '../services/slcapi.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SessionGroup } from '../models/app.models';
import { EventComponent } from '../components/event/event.component';

@Component({
  selector: 'app-livestream',
  templateUrl: './livestream.page.html',
  styleUrls: ['./livestream.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, EventComponent]

})
export class LivestreamComponent implements OnInit {

  days: SessionGroup[] = [];

  constructor(
    private slcapi: SlcapiService,
    ) {}

     ngOnInit(): void {
      this.slcapi.currentEvents.subscribe(events => {
        if (events) {
          const days = this.slcapi.groupSession(events);
          const daysCopy: SessionGroup[] = this.slcapi.deepCopy(days);
          if (daysCopy) {
            daysCopy.map(d => {
              d.events = d.events.filter(e => e.event_livestreamed === 1);
            });
            this.days = daysCopy;
          }
        }
      });
    }

  getEvents(showLoading: boolean = true) {
    this.slcapi.getEvents(showLoading);
  }

  hasEvents() {
    if (this.days) {
      return this.slcapi.hasEvents(this.days);
    }
  }

}
