import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SlcapiService } from '../../services/slcapi.service';
import { Storage } from '@ionic/storage';
import { StorageService } from '../../services/storage/storage.service';
import { PopoverController, IonicModule, IonModal } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SessionGroup } from 'src/app/models/app.models';
import { OverlayEventDetail } from '@ionic/core/components';
import { EventComponent } from 'src/app/components/event/event.component';
@Component({
  selector: 'app-session-schedule',
  templateUrl: './session-schedule.page.html',
  styleUrls: ['./session-schedule.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, EventComponent]
})
export class SessionScheduleComponent implements OnInit {
  @ViewChild(IonModal) modal: IonModal;

  @Input() id: string = null;

  days: SessionGroup[] = [];
  filteredDays: SessionGroup[];
  eventTypes = [];
  eventTypesCounts = {};
  eventBuildings = [];
  tracks = [];

  years = this.slcapi.getYears().reverse();

  filterManualOptions = {
    type: 'Session',
    day: '',
    title: '',
    building_name: '',
    track: '',
    favorited: null,
  };

  filterManualOptionsDraft = {
    type: 'Session',
    day: '',
    building_name: '',
    track: '',
  };

  defaultOptions: any;

  showYearFilter = false;

  constructor(
    public slcapi: SlcapiService,
    public storage: Storage,
    public popoverController: PopoverController,
    public storageService: StorageService,
    ) {}

   async ngOnInit() {
    this.slcapi.currentEvents.subscribe(events => {
      if (events) {
        const filterTypes = this.slcapi.getEventPropsValuesWithCount(events, ['type', 'building_name', 'track']);
        if (filterTypes) {
          this.eventBuildings = filterTypes.building_name;
          this.tracks = filterTypes.track;
          this.eventTypes = filterTypes.type;
        }
        const days = this.slcapi.groupSession(events);
        const daysCopy: SessionGroup[] = this.slcapi.deepCopy(days);

        if (daysCopy) {
          // daysCopy.map(d => {
          //   d.events = d.events.filter(e => e.type === 'Session');
          // });
          // this.sortEvents();
          this.filteredDays = this.slcapi.deepCopy(daysCopy);
          this.days = daysCopy;

          this.slcapi.currentFilter.subscribe(filter => {
            if (filter) {
              Object.keys(filter).map(k => {
                const v = filter[k];
                this.filterManualOptions[k] = v;
              });
              this.filterManualDays();
            }
          });

          this.filterManualDays();
        }
      }
    });
  }

  toggleYear() {
    this.showYearFilter = !this.showYearFilter;
  }

  sortEvents() {
    for (const day of this.days) {
      if (day.events?.length) {
        day.events.sort((a, b) => a.weight < b.weight ? 0 : 1);
      }
    }
  }

  defaultFilters() {
    this.defaultOptions = this.slcapi.deepCopy(this.filterManualOptionsDraft);
  }

  getEvents(showLoading: boolean = true) {
    this.slcapi.getEvents(showLoading);
  }

  cancel() {
    this.filterManualOptionsDraft = this.defaultOptions;
    this.defaultOptions = null;
    this.modal.dismiss(null, 'cancel');
  }

  confirm() {
    Object.keys(this.filterManualOptionsDraft).map(k => {
      this.filterManualOptions[k] = this.filterManualOptionsDraft[k];
    });
    this.slcapi.filter.next(this.filterManualOptions);
    this.modal.dismiss(true, 'confirm');
  }

  onWillDismiss(event: Event) {
    const ev = event as CustomEvent<OverlayEventDetail<string>>;
    if (ev.detail.role === 'confirm') {
      this.filterManualDays();
    }
  }

  toggleFavorited() {
    this.filterManualOptions.favorited = !this.filterManualOptions.favorited;
    this.filterManualDays();
  }
  
  hasEvents() {
    if (this.filterManualOptions.favorited) {
      return this.slcapi.hasEvents(this.days);
    } else {
      return true;
    }
  }

  filterManualDays() {
    if (this.modal) {
      this.modal.dismiss();
    }

    for (let i = 0; i < this.days.length; i++) {
      this.days[i].events = this.filteredDays[i].events.filter(x => {
        for (const key of Object.keys(this.filterManualOptions)) {
          const val = this.filterManualOptions[key];
          
          if (key === 'title') {
            if (x.room.toLowerCase().indexOf(val.toLowerCase()) !== -1 || 
                x.title.toLowerCase().indexOf(val.toLowerCase()) !== -1) {
              continue;
            } else {
              return false; // Exclude session
            }
          } else if (val && x[key] !== val) {
            return false; // Exclude session
          }
        }
        
        return true; // Include session
      });  
    }
  }


}
