import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment.prod';
import { Observable, firstValueFrom, of, throwError } from 'rxjs';
import { MessagesService } from './messages.service';
import { StorageService } from './storage/storage.service';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private message: MessagesService,
    public storageService: StorageService,
    public loadingCtrl: LoadingController) { }

    setupRequestOptions(type: string, session?: any) {
      switch (type) {
        case 'json':
          return { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        case 'basic':
          const headersBasic = {
            'Content-Type': 'application/json',
            Authorization: 'Basic ' + btoa(environment.appSettings.AppUser.username + ':' + environment.appSettings.AppUser.password)
          };
          const requestOptionsBasic = {headers: new HttpHeaders(headersBasic)};
          return requestOptionsBasic;
        case 'cookie':
          const headersCookie = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Cookie': session.session_name + '=' + session.sessid,
          };
          const requestOptions = {headers: new HttpHeaders(headersCookie)};
          return requestOptions;
        case 'csrf':
          const headersCSRF = {
            'Content-Type': 'application/json',
            'X-Cookie': session.session_name + '=' + session.sessid,
            'X-CSRF-Token': session.token
          };
          const requestOptions2 = {headers: new HttpHeaders(headersCSRF)};
          return requestOptions2;
        default:
          const headersDefault = {
            'Content-Type': 'application/json'
          };
          const defaultRequestOptions = {headers: new HttpHeaders(headersDefault)};
          return defaultRequestOptions;
      }
    }
  
    get(url: string, options: any): Observable<any> {
      return this.http.get(url, options).pipe(
      map(res => {return this.cleanObject(res)}),
      catchError(err => {
        console.error(err);
        return throwError(err);
      }));
    }
  
    post(url: string, body: any, options: any): Observable<any> {
      return this.http.post(url, body, options).pipe(
      map(res => res),
      catchError(async err => {
        console.error(err);
        return of(err);
      }));
    }
  
    cleanObject(obj: any) {
      if (obj) {
        Object.entries(obj).map(([k, v], i) => {
          let newVal = obj[k];
          if (typeof(obj[k]) === 'string') {
            newVal = obj[k].trim();
          try {
              newVal = JSON.parse(newVal);
              obj[k] = newVal;
              this.cleanObject(newVal);
            } catch (err) {
              // obj[k] = newVal;
            }
            if (typeof(newVal) === 'object' && !Array.isArray(newVal) && newVal !== null) {
              Object.entries(newVal).map(([nestedKey, nestedValue], x) => {
                if (k === nestedKey) {
                  obj[k] = this.cleanObject(nestedValue);
                }
              });
            }
            if (typeof(newVal) === 'object' && Array.isArray(newVal) && newVal !== null && newVal.length === 1) {
              obj[k] = this.cleanObject(newVal);
            }
          } else if (typeof(v) === 'object' && Array.isArray(v) && v !== null && v.length === 1) {
              obj[k] = this.cleanObject(v);
          } else if (typeof(v) === 'object' && !Array.isArray(obj[k]) && obj[k] !== null) {
            this.cleanObject(obj[k]);
          } else if (typeof(v) === 'object' && Array.isArray(obj[k]) && obj[k] !== null && obj[k].length > 1) {
            for (const innerObj of obj[k]) {
              this.cleanObject(innerObj);
            }
          } else {
            this.cleanObject(newVal);
          }
        });
        return obj;
      }
    }
  
    async checkSavedDelegate() {
      let delegate = {};
        await this.storageService.get('delegates').then(async (delegateDATA) => {
          if (delegateDATA !== null || delegateDATA !== undefined) {
            delegate = await delegateDATA;
          }
        });
      return delegate;
    }  

    public createDiscussionWebformSubmission(delegate, discussion, question) {
      this.message.showLoading('Submitting your question...', true, 5000);
      const body = {
        webform: 'd170dc33-302f-413e-bc5a-888c36647356',
        submission: {
          uid: '4656',
          data: {
            1: { values: [delegate.register_first_name] },
            2: { values: [delegate.register_last_name] },
            3: { values: [delegate.register_email] },
            4: { values: [delegate.linked_church] },
            5: { values: [delegate.register_phone_number] },
            6: { values: [delegate.nid] },
            7: { values: [delegate.register_delegate_type_value] },
            8: { values: [discussion] },
            9: { values: [question] }
          }
        }
      };
  
      return this.http.post(environment.webform.create, body, this.setupRequestOptions('basic')).subscribe(res => {
        this.loadingCtrl.dismiss();
        this.message.presentToast('Thanks for your question!', 2000);
        return res;
      }, err => {
        this.message.presentToast('There was a problem. Please try again.', 2000);
        this.loadingCtrl.dismiss();
      });
    }
  
  async createFeedbackWebformSubmission(delegate, feedback) {
    this.message.showLoading('Submitting your feedback...', true, 5000);
    const churchTitle = delegate.church?.length ? delegate.church[0].title : '';
    const churchNid = delegate.church?.length ? delegate.church[0].nid : '';
    const body = {
      webform: '3bf468a5-c065-44c2-894f-1449c6894420',
      submission: {
        uid: delegate.uid,
        data: {
          1: { values: [delegate.register_first_name] },
          11: { values: [delegate.register_last_name] },
          4: { values: [delegate.register_email] },
          12: { values: [churchTitle] },
          15: { values: [churchNid] },
          13: { values: [delegate.register_phone_number] },
          10: { values: [delegate.nid] },
          14: { values: [delegate.register_delegate_type_value] },
          9: { values: [feedback.easy] },
          17: { values: [feedback.heard] },
          18: { values: [feedback.helpful] },
          19: { values: [feedback.futureSessions] },
          7: { values: [feedback.changes] },
          2: { values: [feedback.comments] },
        }
      }
    };
    const appUser = await this.storageService.get('appUser');
    const options = this.setupRequestOptions('csrf', appUser);
    return firstValueFrom(this.http.post(environment.webform.create, body, options).pipe(map(res => {
      this.loadingCtrl.dismiss();
      this.message.presentToast('Thanks for your feedback!', 2000);
      console.log(res);
      delegate.webform_feedback = res;
      this.storageService.storeEntity('delegates', delegate, 'nid');
      return res;
    }, () => {
      this.message.presentToast('There was a problem. Please try again.', 2000);
      this.loadingCtrl.dismiss();
    })));

  }

}
