// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-footer ion-col {
  padding: 0;
}
ion-footer ion-col ion-button {
  margin: 0;
  --border-radius: 0;
}
ion-footer ion-col ion-button.non-highlighted {
  --background: #e2e2e2;
  --color: #a2a2a2;
}

textarea,
ion-select {
  --border: 1px solid #ccc;
}`, "",{"version":3,"sources":["webpack://./src/app/modals/feedback/feedback.page.scss"],"names":[],"mappings":"AACI;EACI,UAAA;AAAR;AACQ;EACI,SAAA;EACA,kBAAA;AACZ;AACQ;EACI,qBAAA;EACA,gBAAA;AACZ;;AAIA;;EAEI,wBAAA;AADJ","sourcesContent":["ion-footer {\n    ion-col {\n        padding: 0;\n        ion-button {\n            margin: 0;\n            --border-radius: 0;\n        }\n        ion-button.non-highlighted {\n            --background: #e2e2e2;\n            --color: #a2a2a2;\n        }\n    }\n}\n\ntextarea,\nion-select {\n    --border: 1px solid #ccc;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
