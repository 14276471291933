import { FormGroup } from '@angular/forms';
import { DrupalFormControlObject } from './form-controls';

export interface FormFromJSON {
  bundle_info: any;
  form_fields: Array<DrupalFormControlObject>;
  formGroup?: FormGroup;
  nestedFieldGroups?: Array<DrupalFormControlObject>;
  entityType?: string;
  entityBundle?: string;
  formSubmitLabel?: string;
  formPreviewLabel?: string;
  activeIndex?: number;
}

export interface FieldGroup {
  field_name: string;
  label: string;
  description: string;
  type: string;
  ionic_type: string;
  status: boolean;
  access: boolean;
  visible: boolean;
  fields: Array<DrupalFormControlObject>;
}

export interface FoundRegistrations {
    headers: Array<string>;
    pager: Pager;
    paidCount: number;
    results: Array<any>;
    noResultsData?: boolean;
  }

  export interface Pager {
    count: number;
    limit: number;
    page: number;
    pages: number;
    links?: Array<number>;
  }

  export class FormData {
    [key: string]: any;
    id: string;
    entity_id?: string;
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    gender: string;
    created: string;
    edit = false;
    constructor(id: string, firstName: string, lastName: string) {
      this.id = id;
      this.first_name = firstName;
      this.last_name = lastName;
    }
  }
