import { CommonModule } from "@angular/common";
import { Component, Input, ViewChild } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { Router } from "@angular/router";
import { Share } from '@capacitor/share';
import { IonicModule, LoadingController, ModalController } from "@ionic/angular";
import { SLCEvent } from "src/app/models/app.models";
import { SlcapiService } from "src/app/services/slcapi.service";
import { StorageService } from "src/app/services/storage/storage.service";
import { SessionDetailsComponent } from "src/app/sessions/session-details/session-details.page";
import { environment } from "src/environments/environment.prod";

@Component({
    selector: 'app-slc-event',
    templateUrl: './event.component.html',
    styleUrls: ['./event.component.scss'],
    standalone: true,
    imports: [IonicModule, CommonModule, FormsModule]
})
export class EventComponent {
    @Input() event: SLCEvent;
    constructor(
        private storageService: StorageService,
        private router: Router,
        private loadingCtrl: LoadingController,
        private modalCtrl: ModalController,
        private slcapi: SlcapiService,
        ) {}

    navigate(event: SLCEvent) {
        this.storageService.setData(event.nid, event);
        this.router.navigate([`/sessions/${event.nid}`]);
    }

    async toggle(e: any, event: SLCEvent) {
      e.stopPropagation();
      this.slcapi.toggleEvent(event);
    }

    async openModal(event: SLCEvent) {
      const modal = await this.modalCtrl.create({
        component: SessionDetailsComponent,
        componentProps: {
          event
        }
      });
      await modal.present();
      await modal.onDidDismiss().then(res => {

      });
    }

    async showLoading(title) {
        const loading = await this.loadingCtrl.create({
          message: title,
          backdropDismiss: true,
          duration: 3000
        });
        await loading.present();
        await loading.onDidDismiss();
      }
    
      async shareSession(session: SLCEvent) {
        this.showLoading('Formatting the session data for you...');
        const options = {
          message: 'Here is session by ' + session.speaker + ' about ' + session.title + ' on '
          + session.day + ' at ' + session.from + ' in ' + session.room + '.',
          subject: 'SLC ' + session.year + ' Session - ' + session.title,
          url: environment.node.baseURL + session.nid,
        };
        await Share.share(options)
          .then(() => {
            this.loadingCtrl.dismiss();
          })
          .catch(() => {
            this.loadingCtrl.dismiss();
          });
      }
    
}