import { Injectable } from '@angular/core';

import { MainService } from '../main/main.service';
import { FileEntity, CreatedFile, FileAttachToEntity } from '../models';

/**
 * file service for drupal
 *
 * @see BACK_END/admin/structure/services/list/END_POINT/resources for more details
 */
@Injectable()
export class FileService extends MainService {
  override entityType = 'file';

  /**
   * impelement index resource
   *
   * @return array of files
   */
  getAllFiles(): Promise<FileEntity[]> {
    return this.get();
  }

  /**
   * implement retrieve resource
   *
   * @param fid file id to retrieve
   * @return object of the file entity
   */
  getFileById(fid: number): Promise<FileEntity> {
    return this.get(fid);
  }

  /**
   * implement create resource
   *
   * @param file file entity to create
   * @return created file details
   */
  createFile(file: FileEntity): Promise<CreatedFile> {
    return this.post(file);
  }

  /**
   * implement delete resource
   *
   * @param fid file id to delete
   * @return boolean array if file deleted, otherwise return false
   * keep in mined that you can not delete a file if it is already referenced by a node
   */
  deleteFile(fid: number): Promise<boolean[]> {
    return this.delete(fid);
  }

  /**
   * creating raw file
   * this require rest support for form multi data
   */
  createRaw(): Promise<boolean[]> {
    return this.post({}, 'create_raw');
  }

   /**
    * implement attach_files resource
    *
    * @file object contains the field details and the file to upload
    * @return attached file
    */
   attachFilesToEntity(file: FileAttachToEntity): Promise<FileEntity> {
    return this.post(file, 'attach_to_entity');
  }

}
