import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private _storage: Storage | null = null;

  constructor(private storage: Storage) {
    this.init();
  }

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    const storage = await this.storage.create();
    this._storage = storage;
  }

  // Create and expose methods that users of this service can
  // call, for example:
  set(key: string, value: any) {
    this._storage.set(key, value);
  }

  getEach() {
    this._storage?.forEach((value, key, index) => {
      this.get(key).then(val => {
        console.log(val);
      });
    });
  }

  async get(key: string) {
    return await this.storage?.get(key);
  }

  getAll(key: string): Observable<any> {
    return from(this._storage.get(key)).pipe(
      map((storedOperations: any) => {
        const storedObj = JSON.parse(storedOperations);
        if (storedObj && storedObj.length > 0) {
          console.log(storedObj);
          return storedObj;
        } else {
          console.log('no local data for type: ' + key);
          return of(false).toPromise();
        }
      })
    );
  }

  async clear() {
    this._storage.forEach((v, k, i) => {
        this._storage.remove(k);
    });
  }

  async remove(storageKey: string) {
    return this._storage.remove(storageKey).then(res => res).catch(() => false);
  }


}



