import { Component } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'app-childcare',
  templateUrl: './childcare.page.html',
  styleUrls: ['./childcare.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule]
})
export class ChildcareComponent{

  childcareInfo = '';

  constructor(
    private auth: AuthService,
    private user: UserService    
    ) {
    this.getChildcareLocations();
   }

  async getChildcareLocations() {
    const session = await this.user.getAppUser();
    const options = this.auth.setupRequestOptions('csrf', session);
    return this.auth.get(environment.views.childcare, options).subscribe(res => {
      this.childcareInfo = res.body.und[0].value;
      return this.childcareInfo;
    });
  }


}
