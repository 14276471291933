import { Component, OnInit } from '@angular/core';
import { LoadingController, AlertController, IonicModule, Platform } from '@ionic/angular';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { environment } from '../../environments/environment.prod';
import { StorageService } from '../services/storage/storage.service';
import { Session, UserService } from '../services/user.service';
import { MessagesService } from '../services/messages.service';
import { AuthService } from '../services/auth.service';
import { CodeScanService } from '../services/codescan.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { DelegateComponent } from '../components/delegate/delegate.component';
import { SlcapiService } from '../services/slcapi.service';
import { SessionGroup } from '../models/app.models';
import { EventComponent } from '../components/event/event.component';

@Component({
  selector: 'app-my-id',
  templateUrl: './my-id.page.html',
  styleUrls: ['./my-id.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, NgxQRCodeModule, DelegateComponent, EventComponent]
})
export class YourIDComponent implements OnInit {
  days: SessionGroup[] = [];
  searchTerm = '';
  delegates: Array<any> = [];
  hasDelegates: boolean = false;
  appUser: Session;

  constructor(
    private auth: AuthService,
    private user: UserService,
    public platform: Platform,
    private message: MessagesService,
    private storageService: StorageService,
    private slcapi: SlcapiService,
    private loadingCtrl: LoadingController,
    private scanner: BarcodeScanner,
    private codeScan: CodeScanService,
    private alertCtrl: AlertController) {
    }

    ngOnInit(): void {
      this.slcapi.currentEvents.subscribe(events => {
        if (events) {
          const days = this.slcapi.groupSession(events);
          const daysCopy: SessionGroup[] = this.slcapi.deepCopy(days);
          if (daysCopy) {
            daysCopy.map(d => {
              d.events = d.events.filter(e => e.favorited);
            });
            this.days = daysCopy;
          }
        }
      });
    }

  ionViewWillEnter() {
    this.checkAppUser();
    this.checkSavedDelegateInfo();
  }

  hasEvents() {
    return this.slcapi.hasEvents(this.days);
  }

  async checkAppUser() {
    this.appUser = await this.user.getAppUser();    
  }

  async refreshDelegates() {
    this.message.presentToast('Syncing your delegate\'s information', 2000);
    const delegates = await this.storageService.get('delegates');
    if (delegates && delegates.length) {
      const result = [];
      for await (const delegate of delegates) {
        const options = this.auth.setupRequestOptions('csrf', this.appUser);
        await this.auth.get(environment.views.delegate + 'scanned/' + delegate.nid + '.json', options).toPromise().then(async (data) => {
          data = this.setupQRCode(data.conference_delegates[0]);
          await this.storageService.storeEntity('delegates', data, 'nid');
          result.push(data);
        }, () => {
          this.message.presentAlert('Unable to locate delegate',
          'Please contact a conference representative for support.');
        });
      }
      this.message.presentToast('Updated your delegate\'s information', 2000);
      return this.delegates = result;
    }
  }

  async scanCode() {
    this.checkAppUser();
    this.scanner.scan().then(async (barcodeData) => {
      if (!barcodeData.cancelled) {
        this.message.showLoading('Setting up your info...', false, 3000);
        const barcodeID = await this.codeScan.parseScan(barcodeData.text).then(res => res.id);
        const url = `${environment.views.delegate}scanned/${barcodeID}`;
        this.getNewDelegate(url);
      }
    });
  }

  async setupLinkedDelegate(form: any, linkSpouse: boolean = true) {
    let url = environment.views.delegate + form.nid + '/' + form.last_name;
    if (!linkSpouse) {
      url = environment.views.delegate + 'scanned/' + form.nid;
    }
    this.getNewDelegate(url);
  }

  async getNewDelegate(url: string) {
    const options = this.auth.setupRequestOptions('csrf', this.appUser);
    this.auth.get(url, options).subscribe(async (delegate) => {
      if (delegate.conference_delegates.length) {
        this.storeDelegate(delegate, options);
      } else {
        this.checkInfo();
      }
    }, () => {
        this.checkInfo();
    });
  }

  async storeDelegate(entity: any, options: any, linkSpouse: boolean = true) {
    entity = this.setupQRCode(entity.conference_delegates[0]);
    if (this.delegates) {
      const index = this.delegates.map((o) => o.nid).indexOf(entity.nid);
      if (index !== -1) {
        await this.storageService.storeEntity('delegates', entity, 'nid');
        this.delegates[index] = entity;
        this.message.presentAlert('Delegate already linked.', entity.register_first_name + ' ' + entity.register_last_name + ' is already linked.');
      } else {
        await this.storageService.storeEntity('delegates', entity, 'nid');
        this.delegates.unshift(entity);
        if (entity.linked_spouse && linkSpouse) {
          const spouseID = entity.linked_spouse;
          this.auth.get(environment.views.delegate + 'scanned/' + spouseID, options).subscribe(async (data) => {
            if (data.conference_delegates.length) {
              data = this.setupQRCode(data.conference_delegates[0]);
              await this.storageService.storeEntity('delegates', data, 'nid');
              this.delegates.unshift(data);
            } else {
              this.checkInfo();
            }
          });
        }
      }
    } else {
      this.delegates.unshift(entity);
      this.hasDelegates = true;
    }
    this.hasDelegates = true;
  }

  async checkSavedDelegateInfo() {
    this.delegates = await this.storageService.get('delegates') ? await this.storageService.get('delegates') : [];
    if (this.delegates?.length) {
      this.hasDelegates = true;
    } else {this.hasDelegates = false;}
    return this.delegates;
  }

  async filterResults() {
    const allDelegates = await this.storageService.get('delegates');
    if (allDelegates) {
      if (this.searchTerm) {
        this.delegates = allDelegates.filter(delegate => delegate.title.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1);
      } else {
        this.delegates = allDelegates;
      }
      this.hasDelegates = true;
    }
  }

  async presentSpouseAlert(title, body, id) {
    const alert = await this.alertCtrl.create({
      header: title,
      message: body,
      buttons: [
        {
        text: 'Don\'t Link',
        role: 'cancel',
        },
        {
          text: 'Add Spouse',
          handler: () => {
            this.message.showLoading('Adding your spouse...', false, 3000);
            this.setupLinkedDelegate({nid: id}, false);
          }
        }
      ]
      });
    await alert.present();
  }

  async getDelegateInfoManual() {
    const alert = await this.alertCtrl.create({
      header: 'Link Delegate ID',
      cssClass: 'manual-entry',
      inputs: [
        {
          name: 'last_name',
          type: 'text',
          id: 'last_name',
          placeholder: 'Last Name'
        },
        {
          name: 'nid',
          type: 'tel',
          id: 'nid',
          placeholder: 'Delegate ID'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
        }, {
          text: 'OK',
          handler: form => {
            this.message.showLoading('Setting up your info...', false, 3000);
            this.setupLinkedDelegate(form);
          }
        }
      ]
    });

    await alert.present();
  }

  async clearDelegateData() {
    const alert = await this.alertCtrl.create({
      header: 'Unlink Delegate IDs',
      message: 'Are you sure you want to remove your Delegate IDs?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
        }, {
          text: 'REMOVE',
          handler: () => {
            this.delegates = [];
            this.storageService.remove('delegates');
            this.checkSavedDelegateInfo();
          }
        }
      ]
    });
    await alert.present();
}

  setupQRCode(entity: any) {
      entity.qr_codes = [];
      entity.referenced_orders = entity.referenced_orders.referenced_orders;
      if (entity.referenced_orders) {
        for (const order of entity.referenced_orders) {
          const qrCodeDataValue = `%id=${entity.nid}^lineitemid=${order.line_item_id}^orderid=${order.order_id}^uid=${order.uid}?`;
          const qrCodeData = {
            id: entity.nid,
            line_item_id: order.line_item_id,
            order_id: order.order_id,
            uid: order.uid,
            qr_code_value: qrCodeDataValue
          }
          entity.qr_codes.push(qrCodeData);
        }
      }
    return entity;
  }

  checkInfo() {
    this.message.presentAlert('Check Your Info',
        'The info you provided does not match our records. Please check your information and try again.');
  }


}
