import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment.prod';
import { Building, SLCDay, SLCEvent, SLCTime, SessionGroup } from 'src/app/models/app.models';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { AlertController, LoadingController } from '@ionic/angular';
import { StorageService } from './storage/storage.service';

@Injectable({
  providedIn: 'root'
})

export class SlcapiService {

  events = new BehaviorSubject<SLCEvent[]>(null);
  currentEvents = this.events.asObservable();

  filter = new BehaviorSubject<any>(null);
  currentFilter = this.filter.asObservable();

  delegates = new BehaviorSubject<any[]>(null);
  currentDelegates = this.delegates.asObservable();

  eventsURL = 'https://slconference.com/api/events';
  entityNodeURL = 'https://slconference.com/api/entity_node';
  loading: HTMLIonLoadingElement;

  minYear = 1986;
  maxYear = 0;
  year = new Date().getUTCFullYear();
  filterYear = `?event_date_and_time[value][year]=${this.year}`;

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController) {
      this.getEvents();
      // this.getStoredYear();
     }

  changeEvents(events: SLCEvent[]) {
    this.events.next(events);
  }

  async getStoredYear() {
    let year = await this.storageService.get('year');
    if (year) {
      this.year = year;
      this.filterYear = `?event_date_and_time[value][year]=${this.year}`;
    } else {
      this.storageService.set('year', this.year);
    }
  }

  changeYear(event: any) {
    if (event.detail.value) {
      this.year = event.detail.value;
      this.filterYear = `?event_date_and_time[value][year]=${this.year}`;
      this.storageService.set('year', this.year);
      this.getEvents(true);
    }
  }

  async getStoredEvents() {
    let events: SLCEvent[] = await this.storageService.get('allEvents');
    if (events?.length) {
      events = await this.flagFavEvents(events);
      this.changeEvents(events);
    } else {
      this.getEvents();
    }
  }

  async getEvents(showLoading: boolean = false) {
    if (showLoading) {
      this.showLoading(`Loading events for the year ${this.year}...`);
    }
    let allEvents: SLCEvent[] = await this.get(this.eventsURL + this.filterYear).then(res => res.events);

    if (allEvents) {
      // Flag the events that were previously favorited
      allEvents = await this.flagFavEvents(allEvents);
      this.storageService.set('allEvents', allEvents);
      const filteredEvents = this.deepCopy(allEvents);
      this.changeEvents(allEvents);
      await this.dismissLoading();
      return {
        events: allEvents,
        filteredEvents
      }
    }
    await this.dismissLoading();
  }

  async flagFavEvents(allEvents: SLCEvent[]) {
    let favEvents: number[] = await this.storageService.get('favorite_events');
    if (favEvents?.length) {
      favEvents = favEvents.map(e => +e);
      allEvents.map(e => {
        if (favEvents.indexOf(e.nid) > -1) {
          e.favorited = true;
      } else {
        e.favorited = false;
      }
    });
      this.storageService.set('allEvents', allEvents);
      return allEvents;
    } else {
      return allEvents;
    }
  }

  async getFavEvents() {
    const allEvents: SLCEvent[] = await this.storageService.get('allEvents');
    const favEvents: number[] = await this.storageService.get('favorite_events');
    if (favEvents?.length) {
      allEvents.map(e => {
        if (favEvents.indexOf(e.nid) > -1) {
          e.favorited = true;
      } else {
        e.favorited = false;
      }
    });
      this.storageService.set('allEvents', allEvents);
      this.changeEvents(allEvents);
      return allEvents;
    } else {
      allEvents.map(e => e.favorited = false);
      this.storageService.set('allEvents', allEvents);
      this.changeEvents(allEvents);
      return allEvents;
    }
  }

  hasEvents(days: SessionGroup[]) {
    if (days.length) {
      for (const day of days) {
        if (day.events.length) {
          return true;
        }
      }
    }
    return false;
  }

  async toggleEvent(event: SLCEvent) {   
    await this.storageService.checkMyID();
    if (this.storageService.myID) {
      if (!event.favorited) { 
        this.addEvent(event);
      } else if (event.favorited) { 
        this.removeEvent(event);
      }
    } else { 
      this.storageService.pushYourIDPage('To save your favorite sessions');
    }
  }

  getYears() {
    const currentYear = new Date().getFullYear();
    const years: number[] = [];

    for (let i = this.minYear; i <= currentYear + this.maxYear; i++) {
      years.push(i);
    }
    return years;
  }

  async addEvent(event: SLCEvent) {
    event.favorited = true;
    let events = await this.storageService.get('favorite_events');
    if (events) {
      if (events.indexOf(event.nid) === -1) {
        events.push(event.nid);
      }
    } else {
      events = [event.nid];
    }
    this.storageService.set('favorite_events', events);
    this.getFavEvents();
  }

  async removeEvent(event: SLCEvent) {
    event.favorited = false;
    const entities: number[] = await this.storageService.get('favorite_events');
    const storedObj = entities.filter(obj => obj !== event.nid);
    this.storageService.set('favorite_events', storedObj);
    this.getFavEvents();
  }

  async get(url: string, options?: any) {
    return await firstValueFrom(this.http.get(url, options).pipe(
      map(res => this.cleanObject(res)),
    )).then(res => { 
      return this.handleErrors(res);
    }).catch(err => err);
  }

  async post(url: string, body: any, options?: any) {
    return await firstValueFrom(this.http.post(url, body, options).pipe(
      map(res => this.cleanObject(res)),
    )).then(res => { 
      return this.handleErrors(res);
    }).catch(err => err);
  }

  async delete(url: number) {
    const appUser = await this.getAppUser();
    const options = this.setupRequestOptions('csrf', appUser);
    return await firstValueFrom(this.http.delete(`${this.entityNodeURL}/${url}`, options).pipe(
      map(res => this.cleanObject(res)),
    )).then(res => { 
      return this.handleErrors(res);
    }).catch(err => err);
  }

  async getAppUser() {
    const AppUser = {username: 'SLCEvent', password: 'SLCEvent' };
    const url = 'https://slconference.com/api/user/login';

    return await this.storageService.get('slcAppUser').then(async (res) => {
      if (res) {
        return res;
      } else {
        const newSession = await this.post(url, AppUser, false);
        this.storageService.set('slcAppUser', newSession);
        return newSession;
      }
    }, async () => {
      const newSession = await this.post(url, AppUser);
      this.storageService.set('slcAppUser', newSession);
      return newSession;
    });
  }

  async groupEvents(events: any) {
    const result: object = {};
    for await (const event of events) {
      result[event.day] = new SessionGroup(event.day_of_week, event.day, events.filter(o => o.day === event.day), null);
    }
    const arr = Object.values(result).sort((a, b) => a[1] - b[1]);
    return arr;
  }

  getBuildings() {
    return this.http.get<any>(environment.views.campus).pipe(map(res => {
      this.storageService.set('campus', res);
      return res;
    }));
  }

  public getBuildingSessions(roomID) {
    return this.http.get(environment.views.campus + '/rooms/' + roomID).pipe(map (res => {
      const result = [];
      result.push(res);
      const result2 = result[0];
      return result2;
    }));
  }

  public flagEvent(id) {
    return this.http.get(environment.node.baseURL + id + '?flagEvent=1').pipe(map (res => {
      console.log(res);
    }));
  }

  public unflagEvent(id) {
    return this.http.get(environment.flag.unflag + id + '?flagEvent=0').pipe(map (res => {
      console.log(res);
    }));
  }

  deepCopy(obj: any) {
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }
    
    if (Array.isArray(obj)) {
      return obj.map(item => this.deepCopy(item));
    }
    
    const newObj = {};
    for (const key in obj) {
      newObj[key] = this.deepCopy(obj[key]);
    }
    
    return newObj;
  }

    groupObjectsByDayTimeBuildinga(objects: SLCEvent[]) {
      const result = objects.reduce((result, obj) => {
        // Group by day
        if (!result[obj.day]) {
          result[obj.day] = {};
        }
        
        // Group by time
        if (!result[obj.day][obj.from]) {
          result[obj.day][obj.from] = {};
        }
        
        // Group by building
        if (!result[obj.day][obj.from][obj.building_name]) {
          result[obj.day][obj.from][obj.building_name] = [];
        }
        
        // Add object to the group
        result[obj.day][obj.from][obj.building_name].push(obj);
        
        return result;
      }, {});
      console.log(result);
    }
  
    getEventPropValues(objects: SLCEvent[], prop: string) {
      const eventTypes = [];
      for (const session of objects) {
        const eventTypeIndex = eventTypes.findIndex(x => x === session[prop]);
        if (eventTypeIndex === -1 && session[prop]) {
          eventTypes.push(session[prop]);
        }
      }
      return eventTypes;
    }

    getEventPropValuesWithCount(objects: SLCEvent[], prop: string) {
      const eventTypeCounts = {};
      
      for (const session of objects) {
        if (session[prop]) {
          if (!eventTypeCounts[session[prop]]) {
            eventTypeCounts[session[prop]] = 1;
          } else {
            eventTypeCounts[session[prop]]++;
          }
        }
      }
      
      const uniqueEventTypes = Object.keys(eventTypeCounts);
      return { uniqueEventTypes, eventTypeCounts };
    }

    getEventPropValuesWithCountArray(objects: SLCEvent[], prop: string) {
      const eventTypeCounts = {};
    
      for (const session of objects) {
        if (session[prop]) {
          if (!eventTypeCounts[session[prop]]) {
            eventTypeCounts[session[prop]] = 1;
          } else {
            eventTypeCounts[session[prop]]++;
          }
        }
      }
    
      const eventTypeArray = Object.keys(eventTypeCounts).map(eventType => ({
        name: eventType,
        count: eventTypeCounts[eventType],
      }));
    
      return eventTypeArray;
    }

    getEventPropsValuesWithCount(objects: SLCEvent[], props: string[]) {
      const eventTypeCounts = {};
    
      for (const session of objects) {
        for (const prop of props) {
          if (session[prop]) {
            if (!eventTypeCounts[prop]) {
              eventTypeCounts[prop] = {};
            }
            if (!eventTypeCounts[prop][session[prop]]) {
              eventTypeCounts[prop][session[prop]] = 1;
            } else {
              eventTypeCounts[prop][session[prop]]++;
            }
          }
        }
      }
    
      const eventTypeArray: any = {};
      for (const prop of props) {
        if (eventTypeCounts[prop]) {
          eventTypeArray[prop] = Object.keys(eventTypeCounts[prop]).sort().map(eventType => ({
            name: eventType,
            count: eventTypeCounts[prop][eventType],
          }));
        }
      }
    
      return eventTypeArray;
    }    
  
    groupObjectsByDayTimeBuilding(objects: SLCEvent[]): SLCDay[] {
      const eventTypes = [];
      const result = objects.reduce((result: SLCDay[], obj) => {
        const eventTypeIndex = eventTypes.findIndex(x => x === obj.type);
        if (eventTypeIndex === -1) {
          eventTypes.push(obj.type);
        }
        // Find the index of the day group
        const dayIndex = result.findIndex(group => group.day === obj.day);
        
        if (dayIndex === -1) {
          // Create a new day group if it doesn't exist
          const dayGroup = new SLCDay(obj.day, [new SLCTime(obj.from, obj.to, [new Building(obj.building_name, [obj])])]);
          result.push(dayGroup);
        } else {
          // Find the index of the time group
          const timeIndex = result[dayIndex].times.findIndex(group => group.from === obj.from);
          
          if (timeIndex === -1) {
            // Create a new time group if it doesn't exist
            const timeGroup = new SLCTime(obj.from, obj.to, [new Building(obj.building_name, [obj])]);
            result[dayIndex].times.push(timeGroup);
          } else {
            // Find the index of the building group
            const buildingIndex = result[dayIndex].times[timeIndex].buildings.findIndex(group => group.building_name === obj.building_name);
            
            if (buildingIndex === -1) {
              const buildingGroup = new Building(obj.building_name, [obj]);
              result[dayIndex].times[timeIndex].buildings.push(buildingGroup);
            } else {
              // Add the session to the existing building group
              result[dayIndex].times[timeIndex].buildings[buildingIndex].events.push(obj);
            }
          }
        }
        
        return result;
      }, []);
    
      return result;
    }
    
  
    groupSession(res: SLCEvent[]): SessionGroup[] {
      const result: { [key: string]: SessionGroup } = res.reduce((acc: { [key: string]: SessionGroup }, event: SLCEvent) => {
        if (!acc[event.day]) {
          acc[event.day] = new SessionGroup(event.day_of_week, event.day, [], event.from);
        }
        acc[event.day].events.push(event);
        return acc;
      }, {});
      const arr = Object.values(result).sort((a: any, b: any) => a[1] - b[1]);
      return arr;
    }

    cleanObject(entity: any) {
      if (entity) {
        Object.entries(entity).map(([k, v], i) => {
          let newVal = entity[k];
          if (typeof(entity[k]) === 'string') {
            newVal = entity[k].trim();
          try {
              newVal = JSON.parse(newVal);
              entity[k] = newVal;
              this.cleanObject(newVal);
            } catch (err) {
            }
            if (typeof(newVal) === 'object' && !Array.isArray(newVal) && newVal !== null) {
              Object.entries(newVal).map(([nestedKey, nestedValue], x) => {
                if (k === nestedKey) {
                  entity[k] = this.cleanObject(nestedValue);
                }
              });
            }
            if (typeof(newVal) === 'object' && Array.isArray(newVal) && newVal !== null && newVal.length === 1) {
              entity[k] = this.cleanObject(newVal);
            }
          } else if (typeof(v) === 'object' && Array.isArray(v) && v !== null && v.length === 1) {
            entity[k] = this.cleanObject(v);
          } else if (typeof(v) === 'object' && !Array.isArray(entity[k]) && entity[k] !== null) {
            this.cleanObject(entity[k]);
          } else if (typeof(v) === 'object' && Array.isArray(entity[k]) && entity[k] !== null && entity[k].length > 1) {
            for (const innerObj of entity[k]) {
              this.cleanObject(innerObj);
            }
          } else {
            this.cleanObject(newVal);
          }
        });
        return entity;
      }
    }
  
    private setupRequestOptions(type: string, session: any) {
      switch (type) {
        case 'json':
          return { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        case 'basic':
          const headersBasic = {
            'Content-Type': 'application/json',
            Authorization: 'Basic ' + btoa('restws_wcbc_apply' + ':' + 'restws_wcbc_apply')
          };
          const requestOptionsBasic = {headers: new HttpHeaders(headersBasic)};
          return requestOptionsBasic;
        case 'cookie':
          const headersCookie = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Cookie': session.session_name + '=' + session.sessid,
          };
          const requestOptions = {headers: new HttpHeaders(headersCookie)};
          return requestOptions;
        case 'csrf':
          const headersCSRF = {
            'Content-Type': 'application/json',
            'X-Cookie': session.session_name + '=' + session.sessid,
            'X-CSRF-Token': session.token
          };
          const requestOptions2 = {headers: new HttpHeaders(headersCSRF)};
          return requestOptions2;
        default:
          const headersDefault = {
            'Content-Type': 'application/json'
          };
          const defaultRequestOptions = {headers: new HttpHeaders(headersDefault)};
          console.log('no headers');
          return defaultRequestOptions;
      }
    }
  
    private async handleErrors(res: any): Promise<any> {
      if (res === undefined) {
        return false;
      }
      if (res?.status && res?.ok === false) {
        switch (res.status) {
          case 403:
            const accessDenied = await this.alertCtrl.create({
              header: '403 Error',
              message: 'Access Denied',
              buttons: ['OK']
            });
            await accessDenied.present();
            return false;
          case 404:
            const notFound = await this.alertCtrl.create({
              header: '404 Error',
              message: 'Not Found',
              buttons: ['OK']
            });
            await notFound.present();
            return false;
          case 500:
            const serverError = await this.alertCtrl.create({
              header: '500 Error',
              message: 'Internal Server Error',
              buttons: ['OK']
            });
            await serverError.present();
            return false;
          default:
            return res;
        }
      } else if (res.name === 'TimeoutError') {
        const timeoutError = await this.alertCtrl.create({
          header: 'Timeout Error',
          message: 'Request timed out',
          buttons: ['OK']
        });
        await timeoutError.present();
        return false;
      } else {
        return res;
      }
    }

    async showLoading(message: string) {
      if (!this.loading) {
        this.loading = await this.loadingCtrl.create({
          message,
          backdropDismiss: true,
        });
        await this.loading.present();
      }
    }

    async dismissLoading() {
      if (this.loading) {
        await this.loading.dismiss();
        this.loading = null;
      }
    }



}
