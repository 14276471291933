import { Routes } from '@angular/router';
import { CampusMapComponent } from './campus-map/campus-map.page';
import { YourIDComponent } from './my-id/my-id.page';
import { ConferenceScheduleComponent } from './conference-schedule/conference-schedule.page';
import { TabsComponent } from './tabs/tabs.page';
import { LivestreamComponent } from './livestream/livestream.page';
import { SessionScheduleComponent } from './sessions/session-schedule/session-schedule.page';
import { SessionDetailsComponent } from './sessions/session-details/session-details.page';
import { ScheduleComponent } from './schedule/schedule.page';
import { ChildcareComponent } from './childcare/childcare.page';
import { FeedbackComponent } from './modals/feedback/feedback.page';

export const routes: Routes = [
  { path: '', component: TabsComponent, children: [
    { path: 'schedule', component: ScheduleComponent},
    { path: 'schedule/conference-schedule', component: ConferenceScheduleComponent },
    { path: 'schedule/campus-map', component: CampusMapComponent },
    { path: 'my-id', component: YourIDComponent },
    { path: 'livestream', component: LivestreamComponent },
    { path: 'sessions', component: SessionScheduleComponent },
    { path: 'sessions/:id', component: SessionDetailsComponent },
    { path: 'feedback', component: FeedbackComponent },
    { path: 'my-id/childcare', component: ChildcareComponent },
    { path: '', redirectTo: 'schedule', pathMatch: 'full'}
  ] },

];
