// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-avatar {
  width: 60px;
  height: 60px;
}

.id-badge {
  max-width: 20rem;
  margin: 0 auto;
}

ion-list {
  background: transparent;
}`, "",{"version":3,"sources":["webpack://./src/app/my-id/my-id.page.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;AACJ;;AAGA;EACI,gBAAA;EACA,cAAA;AAAJ;;AAGA;EACI,uBAAA;AAAJ","sourcesContent":["ion-avatar {\n    width: 60px;\n    height: 60px;\n}\n\n\n.id-badge {\n    max-width: 20rem;\n    margin: 0 auto;\n}\n\nion-list {\n    background: transparent;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
