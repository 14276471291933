import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController, IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-discussion-question',
  templateUrl: './discussion-question.page.html',
  styleUrls: ['./discussion-question.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule]
})
export class DiscussionQuestionComponent implements OnInit {

  event = {title: ''};
  delegate = [];
  question = '';

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    
  ) { }

  ngOnInit() {
    this.event = this.navParams.get('event');
    this.delegate = this.navParams.get('user');
  }

  cancelModal() {
    this.modalController.dismiss();
  }

  filter() {
    this.modalController.dismiss({
      question: this.question
    });
  }

}
