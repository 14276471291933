import { Injectable } from '@angular/core';

import { MainService } from '../main/main.service';
import { CommerceLineItemEntity } from '../models';

/**
 * node service for drupal
 *
 * @see BACK_END/admin/structure/services/list/END_POINT/resources for more details
 */
@Injectable()
export class CommerceLineItemService extends MainService {
  override entityType = 'commerce_line_item';

  /**
   * impelement index resource
   *
   * @return array of lineItems
   */
  getAllLineItems(): Promise<CommerceLineItemEntity[]> {
    return this.get();
  }

  /**
   * implement retrieve resource
   *
   * @param lineItemId lineItem id to retrieve
   * @return object of the lineItem entity
   */
  getLineItemById(lineItemId: number): Promise<CommerceLineItemEntity> {
    return this.get(lineItemId);
  }

  /**
   * implement create resource
   *
   * @param lineItem line item entity to create
   * @return created line item details
   */
  createLineItem(lineItem: CommerceLineItemEntity): Promise<CommerceLineItemEntity> {
    return this.post(lineItem);
  }

  /**
   * implement update resource
   *
   * @param lineItem line item to update
   * @return updated line item details
   */
  updateLineItem(lineItem: {lineItemId: number}): Promise<CommerceLineItemEntity> {
    return this.put(lineItem, lineItem.lineItemId);
  }

  /**
   * implement delete resource
   *
   * @param lineItemId line item id to delete
   * @return array true if line item deleted, otherwise return false array
   */
  deleteLineItem(lineItemId: number): Promise<boolean[]> {
    return this.delete(lineItemId);
  }

}
