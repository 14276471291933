import { Injectable } from '@angular/core';

import { MainService } from '../main/main.service';
import { CommerceOrder, CustomerProfileAttachToEntity } from '../models';

/**
 * node service for drupal
 *
 * @see BACK_END/admin/structure/services/list/END_POINT/resources for more details
 */
@Injectable()
export class CommerceOrderService extends MainService {
  override entityType = 'commerce_order';

  /**
   * impelement index resource
   *
   * @return array of orders
   */
  getAllOrders(): Promise<CommerceOrder[]> {
    return this.get();
  }

  /**
   * implement retrieve resource
   *
   * @param orderId order id to retrieve
   * @return object of the order entity
   */
  getOrderById(orderId: number): Promise<CommerceOrder> {
    return this.get(orderId);
  }

  /**
   * implement create resource
   *
   * @param order order entity to create
   * @return created order details
   */
  createOrder(order: CommerceOrder): Promise<CommerceOrder> {
    return this.post(order);
  }

  /**
   * implement update resource
   *
   * @param order order to update
   * @return updated order details
   */
  updateOrder(order: {orderId: number}): Promise<CommerceOrder> {
    return this.put(order, order.orderId);
  }

  /**
   * implement delete resource
   *
   * @param orderId order id to delete
   * @return array true if order deleted, otherwise return false array
   */
  deleteOrder(orderId: number): Promise<boolean[]> {
    return this.delete(orderId);
  }

   /**
    * implement attach_files resource
    *
    * @file object contains the field details and the file to upload
    * @return attached file
    */
   attachProfileToEntity(customerProfile: CustomerProfileAttachToEntity): Promise<CommerceOrder> {
    return this.post(customerProfile, 'attach_commerce_customer_profile');
  }

}
