  export class SessionGroup {
    day_of_week: string;
    day: string;
    events: SLCEvent[];
    time?: string;
    constructor(dayOfWeek: string, day: string, events: SLCEvent[], time?: string) {
      this.day_of_week = dayOfWeek;
      this.day = day;
      this.time = time;
      this.events = events;
    }
  }

  export class SLCDay {
    day: string;
    times: SLCTime[];
    constructor(day, times = []) {
      this.day = day;
      this.times = times;
    }
  }
  export class SLCTime {
    from: string;
    to: string;
    buildings: Building[];
    constructor(from, to, buildings = []) {
      this.from = from;
      this.to = to;
      this.buildings = buildings;
    }
  }
  export class Building {
    building_name: string;
    events: SLCEvent[];
    constructor(building, events = []) {
      this.building_name = building;
      this.events = events;
    }
  }
  export interface SLCEvent {
    title: string;
    event_message_title: string;
    type: string;
    day_of_week: string;
    day: string;
    from: string;
    to: string;
    year: number;
    room: string;
    speaker: string;
    event_audio_link?: string;
    event_video_link?: string;
    event_outline_pdf?: string;
    nid: number;
    track: string;
    weight: number;
    description: string;
    speakers: Speaker[];
    visibility: string;
    link_title: string;
    link_url: string;
    track_image: ImageSrc;
    event_image: string;
    favorited?: boolean;
    notification_date: string;
    floor_map: ImageSrc;
    building_image: ImageSrc;
    building_name: string;
    panel_speakers: Speaker[];
    panel: string;
    event_livestreamed: number;
    field_attendee_limit: number;
    status: string;
    promote: string;
  }


  export interface ImageSrc {
    src: string;
    alt: string;
    title: string;
  }
  
  export interface Speaker {
    nid: number;
    promote: string;
    speaker_photo: ImageSrc;
    speaker_type: string;
    status: string;
    title: string;
  }
  