import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.page.html',
  styleUrls: ['./feedback.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule]
})
export class FeedbackComponent implements OnInit {

  @Input() delegates: any = [];
  @Input() selectedDelegate: any = null;
  feedback = {
    easy: '',
    heard: '',
    helpful: '',
    futureSessions: '',
    changes: '',
    comments: ''
  };
  webform = '3bf468a5-c065-44c2-894f-1449c6894420';
  webformFields: any;
  formFields = {};

  constructor(
    private modalCtrl: ModalController,
  ) { }

  ngOnInit() {
    console.log('feedback');
  }

  cancelModal() {
    this.modalCtrl.dismiss(null, 'cancel');
  }

  submit() {
    const index = this.delegates.map((o) => o.nid).indexOf(+this.selectedDelegate);
    const delegate = this.delegates[index];
    this.modalCtrl.dismiss({
      feedback: this.feedback,
      delegate
    }, 'submit');
  }

}
